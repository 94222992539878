// import sfkpokal from './products/sfkpokal';
import sfkflamingo from './products/sfkflamingo';
import sfkhirsch from './products/sfkhirsch';
import sfkeinhorn from './products/sfkeinhorn';
import sfkkuh from './products/sfkkuh';
import sfkgiraffe from './products/sfkgiraffe';
import sfkpferd from './products/sfkpferd';
import sfkloewe from './products/sfkloewe';
import sfkduererhase from './products/sfkduererhase';
import sfkchamaeleon from './products/sfkchamaeleon';
import sfkpanda from './products/sfkpanda';
import sfkherz from './products/sfkherz';
import sfkschildkroete from './products/sfkschildkroete';
import sfksparschwein from './products/sfksparschwein';
import sfkkaktus from './products/sfkkaktus';
import sfkbuddha from './products/sfkbuddha';
import sfkvogel from './products/sfkvogel';
import sfkdelfin from './products/sfkdelfin';
import sfkschmetterling from './products/sfkschmetterling';
import sfkfrosch from './products/sfkfrosch';
import sfkweihnachtskrippe from './products/sfkweihnachtskrippe';
import sfkgorilla from './products/sfkgorilla';
import sfkkuerbis from './products/sfkkuerbis';
import sfkamarulaelefant from './products/sfkamarulaelefant';
import sfkfuchs from './products/sfkfuchs';
import sfkxxlpferd from './products/sfkxxlpferd';
import sfkesel from './products/sfkesel';
import sfkanker from './products/sfkanker';
import sfkseepferdchen from './products/sfkseepferdchen';
import sfklama from './products/sfklama';
import sfkfisch from './products/sfkfisch';
import sfkkoifisch from './products/sfkkoifisch';
import sfkweihnachtskugel from './products/sfkweihnachtskugel';
import sfkbaer from './products/sfkbaer';
import sfkvogelhaus from './products/sfkvogelhaus';
import sfkkueken from './products/sfkkueken';
import sfksteinbock from './products/sfksteinbock';
import sfkwal from './products/sfkwal';
import sfkwidder from './products/sfkwidder';
import sfkhahn from './products/sfkhahn';
import sfkengel from './products/sfkengel';
import sfkvaselampion from './products/sfkvaselampion';
import sfkroseShop from './products/sfkroseshop';
import sfktulpeShop from './products/sfktulpeshop';
import sfkgerberaShop from './products/sfkgerberashop';
import sfkgaensebluemchenshop from './products/sfkgaensebluemchenshop';
import sfkosterhaseshop from './products/sfkosterhaseshop';
import sfkballonhund from './products/sfkballonhund';
import sfkherzschachtel from './products/sfkherzschachtel';
import sfkretrobushochzeit from './products/sfkretrobushochzeit';
import sfkpralineshop from './products/sfkpralineshop';
import sfkbabyflascheshop from './products/sfkbabyflascheshop';
import sfkgeschenkboxshop from './products/sfkgeschenkboxshop';
import sfkbanane from './products/sfkbanane';
import windlichtherzshop from './products/windlichtherzshop';
import eichelnshop from './products/eichelnshop';
import adventskalenderBilliardShop from './products/adventskalender-billiard-shop';
import paprika from './products/paprika';
import dalapferd from './products/dalapferd';
import katzeSimba from './products/katzesimba';
import heissluftballon from './products/heissluftballon';
import manekineko from './products/manekineko';
import pilze from './products/pilze';
import nussknacker from './products/nussknacker';
import christbaumschmuckteezeit from './products/christbaumschmuckteezeit';


export default [
  christbaumschmuckteezeit,
  nussknacker,
  pilze,
  manekineko,
  heissluftballon,
  katzeSimba,
  dalapferd,
  paprika,

  eichelnshop,
  windlichtherzshop,
  sfkbanane,
  sfkgeschenkboxshop,
  sfkretrobushochzeit,
  sfkpralineshop,
  sfkherzschachtel,
  sfkbabyflascheshop,
  sfkballonhund,
  sfkosterhaseshop,
  sfkgaensebluemchenshop,
  sfkgerberaShop,
  sfktulpeShop,
  sfkroseShop,
  sfkvaselampion,
  sfkduererhase,
  sfkschmetterling,
  sfkvogelhaus,
  sfkkueken,
  sfkhahn,
  sfkhirsch,
  sfksteinbock,

  sfkesel,
  sfklama,
  sfkbuddha,
  sfkkaktus,

  sfkbaer,
  sfkwidder,

  sfkfuchs,
  sfkxxlpferd,
  sfkloewe,

  sfkeinhorn,
  sfkflamingo,
  sfkkuh,
  sfksparschwein,
  sfkseepferdchen,
  sfkkuerbis,
  sfkwal,
  sfkfisch,

  sfkanker,
  sfkkoifisch,

  sfkamarulaelefant,
  sfkpanda,
  sfkpferd,
  sfkgiraffe,
  sfkgorilla,
  sfkchamaeleon,
  sfkherz,
  sfkfrosch,
  sfkschildkroete,

  sfkvogel,
  sfkdelfin,
  sfkengel,
  sfkweihnachtskugel,
  sfkweihnachtskrippe,
  adventskalenderBilliardShop,

  //sfkpokal,
];
