export default {
  id: '2-33-sfk',
  category: ['home_decor'],

  name: {
    de: ' Dalapferd ',
    en: ' Dala horse',
  },
  subtitle: {
    de: 'B14 x H15 x T5 cm',
    en: 'W14 x H15 x D5 cm',
  },
  meta_title: {
    de: 'Modernes Dalapferd aus Papier basteln',
    en: 'How to make a modern 3d papercraft dala horse',
  },
  meta_description_google: {
    de: 'Bastel dir mit unserer Dalapferd Vorlage ein modernes schwedisches Designobjekt für dein Zuhause.',
    en: 'Create a modern Swedish design object for your home with our papercraft Dala horse template.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  description: {
    de: `Jihaaa! Galoppiere mit unserem Papercraft Dalapferd in die Welt des schwedischen Designs. Dank des Low Poly Aussehens unseres Pferdchens kannst du die kleinen Papierflächen mit schönen Mustern bemalen, bestempeln oder in ihrer Unifarbe belassen.
      Noch heute werden die "Dalahäst" in dem idyllischen Dorf Nusnäs in Handarbeit gefertigt. Wenn du also nicht so weit reisen magst, probier dich an unserer Vorlage aus und gestalte dein ganz persönliches Dalapferd zum Dekorieren oder Verschenken. In Schweden ist es ein traditionell weihnachtliches Dekoelement.
    
    Was ist drin im Bastelset?    
    - 8 vorgedruckte DIN A4 Bögen Tonkarton für 4 Dalapferde 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das Bastelset für Kinder ab 14 Jahren.
    
    `,
    en: `Jihaaa! Gallop into the world of Swedish design with our papercraft Dala horse. Thanks to the low poly look of our little horse, you can paint the small paper surfaces with beautiful patterns, stamp them, or leave them in their solid color.
      Even today, the "Dalahäst" are handcrafted in the idyllic village of Nusnäs. So if you don't want to travel that far, try our template and create your very own Dala horse for decorating or gifting. In Sweden, it's a traditional Christmas decorative element.
      
      What's included in the craft set?    
      - 8 pre-printed A4 sheets of cardstock for 4 Dala horses 
      - 500 double-sided, strongly adhesive pads for clean and quick bonding
      - Detailed step-by-step instructions in German and English for easy assembly
   
      What else do you need?
      A little patience, scissors, ruler, and a scoring tool (e.g., awl, butter knife)

      We recommend the craft set for children aged 14 and up.`,
  },

  pdf: {
    description: {
      de: `Jihaaa! Galoppiere mit unserem Dalapferd in die Welt des schwedischen Designs. Dank des Low Poly Aussehens unseres Pferdchens kannst du die kleinen Papierflächen mit schönen Mustern bemalen, bestempeln oder in ihrer Unifarbe belassen.
      Noch heute werden die "Dalahäst" in dem idyllischen Dorf Nusnäs in Handarbeit gefertigt. Wenn du also nicht so weit reisen magst, probier dich an unserer Vorlage aus und gestalte dein ganz persönliches Dalapferd zum Dekorieren oder Verschenken. In Schweden ist es ein traditionell weihnachtliches Dekoelement. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du mit deinem tierischen Kunstobjekt direkt loslegen. Ich empfehle dir 230g/m² Tonkarton zu verwenden.
    Damit dieses low poly Dalapferd gelingt, benötigst du 2 DIN A4 Bögen Tonkarton, einen Drucker, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,
      en: `Jihaaa! Gallop into the world of Swedish design with our Dala horse. Thanks to the low poly look of our little horse, you can paint the small paper surfaces with beautiful patterns, stamp them, or leave them in their solid color.
        Even today, the "Dalahäst" are handcrafted in the idyllic village of Nusnäs. So if you don't want to travel that far, try our template and create your very own Dala horse for decorating or gifting. In Sweden, it's a traditional Christmas decorative element.
        
        With the PDF template in A4 format for self-printing, you can start right away with your animal art object. I recommend using 230g/m² cardstock.
        To successfully create this low poly Dala horse, you need 2 A4 sheets of cardstock, a printer, scissors, and glue.

        This is a digital product. You will receive the download link via email immediately after payment. This happens within one hour.`,
    },
    price: 7.9,
    priceThirdCountry: 7.9,

    image: {
      filename: {
        de: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
        en: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Zwei blaue Dalapferde',
        en: 'Two blue Dala horses',
      },
    },
  },

  colorinspirations: [
    {
      caption: {
        de: 'Dalapferde in Blautönen',
        en: 'Dala horses in blue shades',
      },
      image: {
        filename: {
          de: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
          en: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
        },
        alt_text: {
          de: 'Dalapferde in Blautönen',
          en: 'Dala horses in blue shades',
        },
      },
    },

    {
      caption: {
        de: 'Bemaltes Dalapferd',
        en: 'Painted Dala horse',
      },
      image: {
        filename: {
          de: 'products/dalapferd/3d-dalapferd-gemustert.jpg',
          en: 'products/dalapferd/3d-dalapferd-gemustert.jpg',
        },
        alt_text: {
          de: 'Beispiel für bemaltes Dalapferd',
          en: 'Example of a painted Dala horse',
        },
      },
    },
    {
      caption: {
        de: 'Rotes Dalapferd - Größenverhältnis',
        en: 'Red Dala horse - size ratio',
      },
      image: {
        filename: {
          de: 'products/dalapferd/modernes-dalapferd-papier-diy.jpg',
          en: 'products/dalapferd/modernes-dalapferd-papier-diy.jpg',
        },
        alt_text: {
          de: 'Rotes Papier low poly Dalapferd vor grüner Wand',
          en: 'Red paper low poly Dala horse against green wall',
        },
      },
    },

    {
      caption: {
        de: 'Vorschau auf die Bastelvorlage',
        en: 'Preview of the craft template',
      },
      image: {
        filename: {
          de: 'products/dalapferd/dalapferd-bastel-vorlage-pdf.jpg',
          en: 'products/dalapferd/dalapferd-bastel-vorlage-pdf.jpg',
        },
        alt_text: {
          de: '3D Dalapferd Bastelvorlage Vorschau PDF',
          en: '3D Dala horse craft template PDF preview',
        },
      },
    },
  ],

  choose: {
    image: {
      filename: {
        de: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
        en: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Selbstgebastelte Dalapferde aus Papier in zwei Blautönen',
        en: 'Self-crafted paper Dala horses in two shades of blue',
      },
    },
    colors: {
      color1: {
        de: 'Dalapferd 1+2',
        en: 'Dala horse 1+2',
      },

      color2: {
        de: 'Dalapferd 3+4',
        en: 'Dala horse 3+4',
      },
    },
    disableColors: {
      color1: ['schwarz', 'bunt'],
      color2: ['schwarz', 'bunt'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
