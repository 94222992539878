export default {
  id: '2-39-sfk',
  category: ['christmas'],

  name: {
    de: ' Baumschmuck Teezeit',
    en: ' ',
  },
  subtitle: {
    de: 'B21 x H40 x T35 cm',
    en: 'W21 x H40 x D35 cm',
  },
  meta_title: {
    de: 'Baumschmuck Teezeit',
    en: 'Paper Ornaments Tea Time',
  },
  meta_description_google: {
    de: 'Kreative und lustige DIY Christbaumanhänger aus Papier – perfekt für die Weihnachtsdeko. Bastel deine individuellen Anhänger mit unserer Voralage',
    en: 'Creative and fun DIY Christmas tree ornaments made from paper - perfect for Christmas decorations. Make your own personalised ornaments with our template',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  description: {
    de: `Verleihe deinem Weihnachtsbaum einen ganz besonderen Charme mit unserem lustigen DIY Baumschmuck aus Papier! In der Vorlage findest du süße 3D Papier Ornamente in Form von Eis, Muffin, Bonbon, Zuckerstange und Teekanne – die perfekten Papierornamente, die das gemütliche Gefühl der Teezeit zu Weihnachten widerspiegeln.

Lass deiner Kreativität freien Lauf und bastle dir deine eigenen Christbaumkugeln mit glänzendem Tonpapier, mit Glitzer für einen Hauch Kitsch und gute Laune oder ganz elegant in einer Farbe. Es macht nicht nur Spaß, sondern sorgt auch für eine entspannende Auszeit. Jedes low poly Ornament ist mit viel Liebe und Detail gestaltet und gibt dir die Möglichkeit, eine persönliche Note in deinen Baum zu bringen. Diese stilvollen Papieranhänger setzen richtig tolle Akzente und sind ein Eye-Catcher, der garantiert die Blicke deiner Gäste auf sich zieht.

Du erhältst den Baumschmuck im Bastelset in einer Farbe. Die Vorlage pro Papierornament befindet sich auf einer Seite. Wenn du die Papierornamente zweifarbig gestalten möchtest, dann solltest du sie in weiß bestellen und anschließend bemalen.`,
    en: `Give your Christmas tree a very special charm with our fun DIY paper tree decorations! In the template you will find cute 3D paper ornaments in the shape of ice cream, cupcakes, sweets, candy canes and teapots - the perfect paper ornaments to reflect the cosy feeling of teatime at Christmas.

    Let your creativity run wild and make your own Christmas tree baubles with shiny clay paper, with glitter for a touch of kitsch and good cheer or very elegantly in one colour. It is not only fun, but also makes for a relaxing break. Each low poly ornament is designed with lots of love and detail and gives you the opportunity to add a personal touch to your tree. These stylish paper tags make really great accents and are an eye-catcher that is guaranteed to catch the eye of your guests.
    
    You will receive the tree decorations in one colour in the set. The template for each paper ornament is on one side. If you would like to design the paper ornaments in two colours, you should order them in white and then paint them.`
,
  },

  pdf: {
    description: {
      de: `Verleihe deinem Weihnachtsbaum einen ganz besonderen Charme mit unserem lustigen DIY Baumschmuck aus Papier! In der Vorlage findest du süße 3D Papier Ornamente in Form von Eis, Muffin, Bonbon, Zuckerstange und Teekanne – die perfekten Papierornamente, die das gemütliche Gefühl der Teezeit zu Weihnachten widerspiegeln.

      Lass deiner Kreativität freien Lauf und bastle dir deine eigenen Christbaumkugeln mit glänzendem Tonpapier, mit Glitzer für einen Hauch Kitsch und gute Laune oder ganz elegant in einer Farbe. Es macht nicht nur Spaß, sondern sorgt auch für eine entspannende Auszeit. Jedes low poly Ornament ist mit viel Liebe und Detail gestaltet und gibt dir die Möglichkeit, eine persönliche Note in deinen Baum zu bringen. Diese stilvollen Papieranhänger setzen richtig tolle Akzente und sind ein Eye-Catcher, der garantiert die Blicke deiner Gäste auf sich zieht.`,

      en: `Give your Christmas tree a very special charm with our fun DIY paper tree decorations! In the template you will find cute 3D paper ornaments in the shape of ice cream, muffins, sweets, candy canes and teapots - the perfect paper ornaments to reflect the cosy feeling of teatime at Christmas.

      Let your creativity run wild and make your own Christmas tree baubles with shiny clay paper, with glitter for a touch of kitsch and good cheer or very elegantly in one colour. It is not only fun, but also makes for a relaxing break. Each low poly ornament is designed with lots of love and detail and gives you the opportunity to add a personal touch to your tree. These stylish paper tags make really great accents and are an eye-catcher that is guaranteed to catch the eye of your guests.`,   
     },
    price: 12.9,
    priceThirdCountry: 12.9,

    image: {
      filename: {
        de: 'products/christbaumschmuckteezeit/lustigen-christbaumschmuck-basteln-papier-eis-muffin-bonbon-zuckerstange-teekanne.jpg',
        en: 'products/christbaumschmuckteezeit/lustigen-christbaumschmuck-basteln-papier-eis-muffin-bonbon-zuckerstange-teekanne.jpg',
      },
      alt_text: {
        de: 'Weihnachtsbaum mit Papierornamenten in 3D: Muffin, Eis, Zuckerstangen, Teekanne, Bonbon',
        en: '',
      },
    },
  } ,

  colorinspirations: [
    {
      caption: {
        de: 'Weihnachtsbaum mit Papierornamenten',
        en: '',
      },

      image: {
        filename: {
          de: 'products/christbaumschmuckteezeit/lustigen-christbaumschmuck-basteln-papier-eis-muffin-bonbon-zuckerstange-teekanne.jpg',
          en: 'products/christbaumschmuckteezeit/lustigen-christbaumschmuck-basteln-papier-eis-muffin-bonbon-zuckerstange-teekanne.jpg',
        },
        alt_text: {
          de: 'Weihnachtsbaum mit Papierornamenten',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Zuckerstange',
        en: 'Paint each facet',
      },
      image: {
        filename: {
          de: 'products/christbaumschmuckteezeit/papier-ornament-zuckerstangen-weihnachten-basteln.jpg',
          en: 'products/christbaumschmuckteezeit/papier-ornament-zuckerstangen-weihnachten-basteln.jpg',
        },
        alt_text: {
          de: '5 Anhänger aus Papier in Form einer Zuckerstange in rot und weiß für den Weihnachtsbaum',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Muffin',
        en: '',
      },
      image: {
        filename: {
          de: 'products/christbaumschmuckteezeit/papier-ornament-muffin-weihnachten-basteln.jpg',
          en: 'products/christbaumschmuckteezeit/papier-ornament-muffin-weihnachten-basteln.jpg',
        },
        alt_text: {
          de: 'Ein 3D Papier Muffin als Baumschmuckanhänger mit roten Punkten',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Eis',
        en: '',
      },
      image: {
        filename: {
          de: 'products/christbaumschmuckteezeit/papier-ornament-eis-weihnachten-basteln.jpg',
          en: 'products/christbaumschmuckteezeit/papier-ornament-eis-weihnachten-basteln.jpg',
        },
        alt_text: {
          de: 'Ein 3D Papier Eis als Christbaumanhänger',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Teekanne',
        en: '',
      },
      image: {
        filename: {
          de: 'products/christbaumschmuckteezeit/papier-ornament-teekanne-weihnachten-basteln.jpg',
          en: 'products/christbaumschmuckteezeit/papier-ornament-teekanne-weihnachten-basteln.jpg',
        },
        alt_text: {
          de: 'Eine 3D Teekanne aus Papier im low poly Stil als Papierornament an einem Tannenzweig ',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Bonbon',
        en: '',
      },
      image: {
        filename: {
          de: 'products/christbaumschmuckteezeit/papier-ornament-bonbon-weihnachten-basteln.jpg',
          en: 'products/christbaumschmuckteezeit/papier-ornament-bonbon-weihnachten-basteln.jpg',
        },
        alt_text: {
          de: '5 Papierornamente in Form eines Bonbons in rot und weiß für den Weihnachtsbaum',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Vorschau auf die Bastelvorlage',
        en: 'Preview of template',
      },
      image: {
        filename: {
          de: 'products/christbaumschmuckteezeit/bastelvorlage-baumschmuck-teezeit-vorschau.jpg',
          en: 'products/christbaumschmuckteezeit/bastelvorlage-baumschmuck-teezeit-vorschau.jpg',
        },
        alt_text: {
          de: '3D Papercraft Katze Bastelvorlage Vorschau PDF',
          en: '3D Papercraft Cat Craft Template Preview PDF',
        },
      },
    },

    
  ],

  choose:{ 
    image: {
      filename: {
        de: 'products/christbaumschmuckteezeit/lustigen-christbaumschmuck-basteln-papier-eis-muffin-bonbon-zuckerstange-teekanne.jpg',
        en: 'products/christbaumschmuckteezeit/lustigen-christbaumschmuck-basteln-papier-eis-muffin-bonbon-zuckerstange-teekanne.jpg',
      },
      alt_text: {
        de: 'Baumschmuck Teezeit von PaperShape',
        en: 'Paper Ornaments from PaperShape',
      },
    },
    colors: {
      color1: {
        de: 'Baumschmuck',
        en: 'Ornaments',
      },
    },
    disableColors: {
      color1: ['schwarz'],
      color2: ['schwarz'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
